import { Link } from "react-router-dom";
import "./Menu.scss";
import MenuImage from "images/photos/Header.jpg";

export default function Menu({ open, toggleMenu }) {
  let className = "menu" + (open ? " menu--open" : "");

  return (
    <div className={className}>
      <div className="menu__container">
        <div className="menu__body">
          <div className="menu__body__close">
            <button onClick={toggleMenu}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
              >
                <path d="M21.474 3.467l-.942-.941L12 11.059 3.466 2.526l-.94.941L11.059 12l-8.533 8.532.94.942L12 12.941l8.532 8.533.942-.942L12.941 12z"></path>
              </svg>
              <div className="menu__body__close__text">Cerrar</div>
            </button>
          </div>
          <div className="menu__body__content">
            <div className="grid">
              <div className="col15 s3">
                <div className="menu__body__main-menu" onClick={toggleMenu}>
                  <Link to="/modelos" className="menu__body__main-menu__item">
                    Modelos
                  </Link>
                  <Link
                    to="/oportunidades"
                    className="menu__body__main-menu__item"
                  >
                    Oportunidades
                  </Link>
                  <Link
                    to="/autoahorro"
                    className="menu__body__main-menu__item"
                  >
                    Autoahorro
                  </Link>
                  <Link to="/postventa" className="menu__body__main-menu__item">
                    Postventa
                  </Link>
                  <Link
                    to="/accesorios"
                    className="menu__body__main-menu__item"
                  >
                    Accesorios
                  </Link>
                  <Link to="/contacto" className="menu__body__main-menu__item">
                    Contacto
                  </Link>
                </div>
              </div>

              <div className="col1 menu__body__spacer">
                <div className=" menu__body__spacer__separator"></div>
              </div>
              <div className="col4 menu__body__secondary-menu">
                <h6>Conoce más sobre Volkswagen</h6>
                <div className="menu__body__secondary-menu__links-list">
                  <Link to="/postventa" onClick={toggleMenu}>
                    Servicios de Taller
                  </Link>
                  <a
                    href="https://territorioyacopini.com.ar/usados/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Usados
                  </a>
                  <a href="https://territorioyacopini.com.ar">
                    Territorio Yacopini
                  </a>
                </div>
                <div className="banner banner--dark">
                  <Link to="/" onClick={toggleMenu}>
                    <div className="image image-3-4">
                      <img src={MenuImage} alt="Yacopini Süd" />
                    </div>
                    <h4>
                      Bienvenido a tu
                      <br /> <strong>Nuevo Volkswagen</strong>{" "}
                    </h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="menu__footer">
          <div className="menu__footer__links">
            <Link to="/contacto">Contacto</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
